<template>
  <div class="ta-users-detail">
    <display-data
      label="Consumer Type"
      :content="taUser.consumerType"
    ></display-data>

    <download-files
      label="Other Documents"
      :downloadItem="taUser.otherDocuments"
      filenamePrefix="ta_other_"
    ></download-files>

    <display-data
      v-if="landlord"
      label="Account Name"
      :content="taUser.accountName"
    ></display-data>
    <display-data
      v-if="landlord"
      label="Account No."
      :content="taUser.accountNumber"
    ></display-data>
    <display-data
      v-if="landlord"
      label="Bank"
      :content="taUser.bank"
    ></display-data>

    <div v-if="taUser.consumerType == CONSUMER_TYPE.COMPANY">
      <display-data
        label="Company Name"
        :content="taUser.companyName"
      ></display-data>
      <display-data
        label="Company No."
        :content="taUser.companyNo"
      ></display-data>
      <download-files
        label="Authorization Files"
        :downloadItem="taUser.authorizationFiles"
        filenamePrefix="ta_authfiles_"
      ></download-files>
      <download-files
        label="SSM Files"
        :downloadItem="taUser.ssmFiles"
        filenamePrefix="ta_ssm_"
      ></download-files>
    </div>

    <div v-if="taUser.consumerType == CONSUMER_TYPE.JOINT">
      <download-files
        label="Relationship Evidence Files"
        :downloadItem="taUser.relationshipFiles"
        filenamePrefix="ta_relship_"
      ></download-files>
    </div>

    <hr class="mt-4" />

    <Tab
      v-model="currentTenantId"
      :tabs="tenantTabs"
      :name="(val) => val.id"
      :label="(val) => val.name"
      class="mt-3"
    ></Tab>

    <UserDetail class="mt-3" :user="currentTenant"></UserDetail>
  </div>
</template>

<script>
import { identificationType, consumerType } from "@/enums";

export default {
  components: {
    Tab: () => import("@/components/GlobalComponents/FdTabs/TabsIso"),
    UserDetail: () => import("@/modules/Rent/components/TAForm/UserDetail"),
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    DownloadFiles: () => import("@/components/GlobalComponents/DownloadFiles")
  },
  mixins: [],
  props: {
    taUser: {
      type: Object,
      required: true
    },
    landlord: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      currentTenantId: this.taUser.users[0].id,

      ID_TYPE: identificationType,
      CONSUMER_TYPE: consumerType
    };
  },
  computed: {
    tenantTabs() {
      return this.taUser.users;
    },
    currentTenant() {
      return this.taUser.users.find((val) => val.id == this.currentTenantId);
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
